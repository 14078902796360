<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img class="swipe-image" :src="bannerImage1" />
      </van-swipe-item>
      <van-swipe-item>
        <img class="swipe-image" :src="bannerImage2" />
      </van-swipe-item>
      <van-swipe-item>
        <img class="swipe-image" :src="bannerImage3" />
      </van-swipe-item>
    </van-swipe>

    <van-grid :column-num="3" square>
      <van-grid-item
        v-for="(value, index) in titleArray"
        :key="index"
        :icon="value.icon"
        :text="value.title"
        :to="value.path"
        @click="clickGrid(value.path)"
      />
    </van-grid>
    <div class="v-footer">
      <div>
        北京医学会放射学分会2021年学术大会
      </div>
      <div>
        京ICP备13024783号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      bannerImage1: require("@/assets/mobile/bg_mobile.jpg"),
      bannerImage2: require("@/assets/mobile/MR.jpg"),
      bannerImage3: require("@/assets/mobile/banner_GE.jpg"),
      titleArray: [
        {
          title: "主席致辞",
          icon: require("@/assets/mobile/home/button_zxzc.svg"),
          path: "/mini/main/miniInvitationLetter",
        },
        {
          title: "组织架构",
          icon: require("@/assets/mobile/home/button_zzjg.svg"),
          path: "/mini/main/miniOrganization",
        },
        {
          title: "专家介绍",
          icon: require("@/assets/mobile/home/button_zjjs.svg"),
          path: "/mini/main/miniExpertIntroduction",
        },
        {
          title: "专家查询",
          icon: require("@/assets/mobile/home/button_zjcx.svg"),
          path: "/mini/main/specialist",
        },
        {
          title: "会议日程",
          icon: require("@/assets/mobile/home/button_hyrc.svg"),
          path: "/mini/main/schedule",
        },
        {
          title: "线上展台",
          icon: require("@/assets/mobile/home/button_xszt.svg"),
          path: "/mini/main/miniCloudHall",
        },
        {
          title: "会议回放",
          icon: require("@/assets/mobile/home/button_hyhf.svg"),
          path: "/mini/main/miniPlayback",
        },
        {
          title: "会议直播",
          icon: require("@/assets/mobile/home/button_hyzb.svg"),
          path: "/mini/meetingLive",
        },
        {
          title: "照片直播",
          icon: require("@/assets/mobile/home/button_zpzb.svg"),
          path: "/mini/photoLive",
        },
        {
          title: "会议手册",
          icon: require("@/assets/mobile/home/button_hysc.svg"),
          path: "/mini/main/miniRegistrationGuide",
        },
        {
          title: "学分注册",
          icon: require("@/assets/mobile/home/button_xfzc.svg"),
          path: "/mini/main/miniCreditInfo",
        },
        {
          title: "会议信息",
          icon: require("@/assets/mobile/home/button_hyxx.svg"),
          path: "/mini/main/miniBasicInfo",
        },
      ],
    };
  },
  methods: {
    clickGrid(path) {
      if (path === "/mini/photoLive") {
        // 照片直播
        window.location.assign("https://live.photoplus.cn/live/55260405");
      } else if (path === "/mini/meetingLive") {
        // 会议直播
        window.location.assign(
          "https://wx.vzan.com/live/mk/aggspread/1560960427/5dc0fa44-d33c-11eb-be34-18ded7a37b78"
        );
      }
    },
  },
  created(){
    this.shareList(
      "https://cdn.821.idoctor.cc/",
      "2021年07月23日-25日",
      "北京医学会放射学分会2021年学术大会"
    );
  }
};
</script>

<style scoped>
.my-swipe .van-swipe-item {
  color: #39a9ed;
  font-size: 20px;
  text-align: center;
}
.my-swipe .swipe-image {
  width: 100%;
  display: block;
}
.van-grid-item >>> .van-grid-item__content {
  background-image: linear-gradient(180deg, #ffffff 0%, #eadfcf 100%);
  /* border: 0.1px solid #DA9007; */
}
.van-grid >>> .van-grid-item__text {
  color: #444444;
  margin-top: 20px;
  font-size: 16px;
}
.van-grid >>> .van-grid-item__icon {
  font-size: 40px;
}
.v-footer {
  background-color: #e6e6e6;
  height: 100px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 30px;
  font-size: 14px;
}

.van-grid >>> [class*="van-hairline"]::after {
  border-color: #da9007;
}
</style>